import React, {lazy} from 'react';
import {Route, Routes, useLocation} from 'react-router-dom';

import MainLayout from './../layout/MainLayout';
import Orders from '../views/orders';

const DashboardDefault = lazy(() => import('../views/dashboard/Default'));

const TableBasic = lazy(() => import('../views/forms/tables/TableBasic'));
const TableDense = lazy(() => import('../views/forms/tables/TableDense'));

const UtilsTypography = lazy(() => import('../views/utilities/typography'));
const UtilsColor = lazy(() => import('../views/utilities/color'));
const UtilsShadow = lazy(() => import('../views/utilities/shadow'));
const UtilsMaterialIcons = lazy(() => import('../views/utilities/icons/MaterialIcons'));
const UtilsTablerIcons = lazy(() => import('../views/utilities/icons/TablerIcons'));

const SamplePage = lazy(() => import('../views/sample-page'));

const MainRoutes = () => {
    const location = useLocation();

    return (
        <MainLayout showBreadcrumb={true}>
            <Routes location={location} key={location.pathname}>
                <Route path="/dashboard/analytics" Component={DashboardDefault} />
                <Route path="/dashboard/orders" Component={Orders} />

                <Route path="/tables/tbl-basic" Component={TableBasic} />
                <Route path="/tables/tbl-dense" Component={TableDense} />

                <Route path="/utils/util-typography" Component={UtilsTypography} />
                <Route path="/utils/util-color" Component={UtilsColor} />
                <Route path="/utils/util-shadow" Component={UtilsShadow} />
                <Route path="/icons/tabler-icons" Component={UtilsTablerIcons} />
                <Route path="/icons/material-icons" Component={UtilsMaterialIcons} />

                <Route path="/sample-page" Component={SamplePage} />
            </Routes>
        </MainLayout>
    );
};

export default MainRoutes;
