import React from 'react';
import {FormattedMessage} from 'react-intl';

import {
    IconAtom,
    IconBasket,
    IconBellRinging,
    IconBorderAll,
    IconBorderRadius,
    IconBoxMultiple,
    IconBrandChrome,
    IconBrandGravatar,
    IconBrush,
    IconBug,
    IconCalendar,
    IconChartArcs,
    IconChartCandle,
    IconChartInfographic,
    IconCircle,
    IconCircleOff,
    IconClipboardList,
    IconDashboard,
    IconDeviceAnalytics,
    IconFiles,
    IconForms,
    IconHelp,
    IconId,
    IconKey,
    IconLayoutList,
    IconLoader,
    IconLockAccess,
    IconMail,
    IconMenu,
    IconMessages,
    IconNfc,
    IconPalette,
    IconPencil,
    IconPhoneCall,
    IconPictureInPicture,
    IconReceipt2,
    IconRun,
    IconShadow,
    IconShape,
    IconShieldLock,
    IconSitemap,
    IconTools,
    IconTypography,
    IconUser,
    IconUserCheck,
    IconFileBarcode,
    IconFile
} from '@tabler/icons';

const icons = {
    IconDashboard: IconDashboard,
    IconDeviceAnalytics,

    IconChartArcs: IconChartArcs,
    IconClipboardList: IconClipboardList,
    IconChartInfographic: IconChartInfographic,

    IconForms: IconForms,
    IconReceipt2: IconReceipt2,
    IconPencil: IconPencil,
    IconPalette: IconPalette,
    IconShadow: IconShadow,
    IconPhoneCall: IconPhoneCall,
    IconBrandChrome: IconBrandChrome,
    IconFiles: IconFiles,
    IconAtom: IconAtom,
    IconTools: IconTools,
    IconBrush: IconBrush,
    IconLockAccess: IconLockAccess,
    IconShieldLock: IconShieldLock,
    IconKey: IconKey,
    IconTypography: IconTypography,
    IconMenu: IconMenu,
    IconBoxMultiple: IconBoxMultiple,
    IconCircleOff: IconCircleOff,
    IconCircle: IconCircle,
    IconBorderRadius: IconBorderRadius,
    IconBrandGravatar: IconBrandGravatar,
    IconShape: IconShape,
    IconUserCheck: IconUserCheck,
    IconId: IconId,
    IconLayoutList: IconLayoutList,
    IconBug: IconBug,
    IconLoader: IconLoader,
    IconRun: IconRun,
    IconUser: IconUser,
    IconHelp: IconHelp,
    IconSitemap: IconSitemap,
    IconPictureInPicture: IconPictureInPicture,
    IconMail: IconMail,
    IconMessages: IconMessages,
    IconNfc: IconNfc,
    IconCalendar: IconCalendar,
    IconBellRinging: IconBellRinging,
    IconBorderAll: IconBorderAll,
    IconChartCandle: IconChartCandle,
    IconBasket: IconBasket,
    IconFileBarcode: IconFile
};

const menuItems = {
    items: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'group',
            children: [
                {
                    id: 'dash-analytics',
                    title: <FormattedMessage id="analytics" />,
                    type: 'item',
                    url: '/app/dashboard/analytics',
                    icon: icons['IconDashboard'],
                    breadcrumbs: false
                }
            ]
        },

        {
            id: 'order-management',
            title: <FormattedMessage id="order management" />,
            // caption: <FormattedMessage id="pages-caption" />,
            type: 'group',
            children: [
                {
                    id: 'orders',
                    title: <FormattedMessage id="ORDERS" />,
                    type: 'collapse',
                    icon: icons['IconFileBarcode'],
                    children: [
                        {
                            id: 'All',
                            title: <FormattedMessage id="All" />,
                            type: 'item',
                            url: '/app/dashboard/orders?status=All',
                            target: false
                        },
                        {
                            id: 'Placed',
                            title: <FormattedMessage id="Placed" />,
                            type: 'item',
                            url: '/app/dashboard/orders?status=Placed',
                            target: false
                        },
                        {
                            id: 'Pending',
                            title: <FormattedMessage id="Pending" />,
                            type: 'item',
                            url: '/login/login3',
                            target: false
                        },
                        {
                            id: 'Accepted',
                            title: <FormattedMessage id="Accepted" />,
                            type: 'item',
                            url: '/login/login3',
                            target: false
                        },
                        {
                            id: 'Processing',
                            title: <FormattedMessage id="Processing" />,
                            type: 'item',
                            url: '/login/login3',
                            target: false
                        }
                    ]
                }
            ]
        }
    ]
};

export default menuItems;
