import {useContext} from 'react';
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import {LoadingContext} from '../context/LoadingContext';
import { HashLoader } from 'react-spinners';

export default function MyLoader({children}) {
    const {isLoading} = useContext(LoadingContext);
    return (
        <LoadingOverlay active={isLoading} spinner={<HashLoader />}>
            {children}
        </LoadingOverlay>
    );
}
