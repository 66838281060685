import React, {useContext, useEffect, useState} from 'react';
import DenseTable from '../forms/tables/TableDense';
import {LoadingContext} from '../../context/LoadingContext';
import ApiManager from '../../utils/api';
import {ORDERS_PATH} from '../../utils/apiRoutes';
import {TableCell, TableRow} from '@material-ui/core';

function Orders() {
    const {hideLoading, showLoading} = useContext(LoadingContext);

    const [orderRows, setOrderRows] = useState([]);
    let headers = ['Placed by', 'Restaurant', 'Amount', 'Pay Mode', 'Status', 'Delivery Accepted'];

    useEffect(() => {
        const fetchData = async () => {
            try {
                showLoading();
                const data = await ApiManager.get(ORDERS_PATH);
                let rows = data.map((order) => {
                    return (
                        <TableRow key={order._id}>
                            {}
                            <TableCell component="th" scope="row">
                                {order.userId?.firstName} {order?.userId?.lastName}
                            </TableCell>
                            <TableCell align="right">{order.restaurantId?.name}</TableCell>
                            <TableCell align="right">{order.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}</TableCell>
                            <TableCell align="right">{order.paymode}</TableCell>
                            <TableCell align="right">{order.status}</TableCell>
                            <TableCell align="right">{order.deliveryAccepted ? "Yes" : "No"}</TableCell>
                        </TableRow>
                    );
                });
                setOrderRows(rows);
            } catch (error) {
                console.log(error);
                alert(error);
            } finally {
                hideLoading();
            }
        };
        fetchData();
    }, []);
    return (
        <>
            <DenseTable headers={headers} rows={orderRows} title="Orders" />
        </>
    );
}

export default Orders;
