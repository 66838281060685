import axios from 'axios';

const BASE_URL = 'https://api.foodorich.com';
export const AUTH_KEY = 'token';
export const USER_KEY = 'user';

const ApiManager = {
    get: async (path, headers = {}) => {
        try {
            const authToken = localStorage.getItem(AUTH_KEY);
            const response = await axios({
                url: BASE_URL + path,
                method: 'GET',
                headers: {
                    Token: `Bearer ${authToken}`
                }
            });
            console.log(response);
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            console.log(error.response.data);
            throw new Error(JSON.stringify(error.response.data));
        }
    },
    post: async (path, data, headers = {}) => {
        try {
            const authToken = localStorage.getItem(AUTH_KEY);
            const response = await axios({
                url: BASE_URL + path,
                method: 'POST',
                headers: {
                    token: authToken,
                    'Content-Type': 'application/json'
                },
                data
            });
            console.log(response);
            console.log(response.data);
            return response.data;
        } catch (error) {
            console.log(error);
            console.log(error.response.data);
            throw new Error(JSON.stringify(error.response.data));
        }
    }
};

export default ApiManager;
