import React, {lazy, Suspense, useEffect, useState} from 'react';
import {Navigate, Routes as ReactRoutes, Route} from 'react-router-dom';
import {AnimatePresence} from 'framer-motion';

import config from './../config';
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';

import Loader from '../ui-component/extended/Loader/Loader';
import AuthenticationRoutes from './AuthenticationRoutes';
import Login from '../views/pages/authentication/login';
import MinimalLayout from '../layout/MinimalLayout';
import {AUTH_KEY} from '../utils/api';

const AuthLogin3 = lazy(() => import('../views/pages/authentication/authentication3/Login3'));
const Routes = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [loading, setLoading] = useState(true); // Add a loading state

    useEffect(() => {
        const token = localStorage.getItem(AUTH_KEY);
        if (token) {
            setIsLoggedIn(true);
        }
        setLoading(false); // Mark loading as false once the check is complete
    }, []);

    if (loading) {
        // Show loader until the check is complete
        return <Loader />;
    }

    return (
        <AnimatePresence>
            <Suspense fallback={<Loader />}>
                <ReactRoutes>
                    {/* Redirect to default path */}
                    <Route path="/" element={<Navigate to={isLoggedIn ? config.defaultPath : '/auth/login'} replace />} />

                    <Route path="/auth/login" element={<AuthLogin3 />} />

                    {/* Routes for main layouts */}
                    {isLoggedIn && <Route path="/app/*" element={<MainRoutes />} />}
                </ReactRoutes>
            </Suspense>
        </AnimatePresence>
    );
};

export default Routes;
